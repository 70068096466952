export const servicesData = [
  {
    _id: 121,
    name: "Art Direction",
    line: "Creating a consistent visual strategy to align with your brand identity and enhance user experience.",
    skills: [
      "Storytelling",
      "Concept Strategy",
      "Visual Consistency",
      "Project Management",
    ],
  },
  {
    _id: 122,
    name: "Graphic Design",
    line: "Crafting visually compelling graphics and imagery to effectively communicate your brand's message",
    skills: [
      "Logo Design",
      "Branding",
      "Identity",
      "Color schemes",
      "Photo Editing",
      "SVG Icons ",
      "Illustrations",
    ],
  },
  {
    _id: 123,
    name: "Web Design",
    line: "Designing visually appealing and user-friendly interfaces for an engaging online experience.",
    skills: ["UI/UX design", "Figma / Prototype", "SVG: icons & animations"],
  },
  {
    _id: 124,
    name: "Web Developement",
    line: "Building and coding functional, responsive websites to ensure seamless performance and usability.",
    skills: [
      "front End",
      "HTML 5",
      "CSS 3",
      "JavaScript",
      "React js",
      "Clean Code",
      "Problem Solving",
    ],
  },
];
