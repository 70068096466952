export const cardsData = [
  {
    id: "ui-ux-design",
    icon: "assets/svg/s-logo-black.svg",
    title: "UI | UX design.",
    slogan: "Your Image is Everything.",
    text: "From the first impression when visiting your website to how users navigate through it, I create meaningful digital experiences tailored to your users' needs. ",
    text_readMore:
      "I start with an appealing User Interface, crafting a distinctive identity and using attractive visuals to showcase your services. This includes art direction, logo design, color schemes, photo refinement, custom icons and illustrations, along with creating unique SVG animations that set your site apart from others. Lastly, I ensure that your users enjoy a seamless journey by designing prototypes and conducting tests to provide the best possible User Experience.",
    text_skills: "I bring ideas to life by using:",
    skills: "Figma, Illustrator & Photoshop.",
  },
  {
    id: "front-end-development",
    icon: "assets/svg/s-logo-black-flip.svg",
    title: "Frontend Development.",
    slogan: "Optimization & Performance are Keys.",
    text: "I seamlessly blend the art of design with skill of programming to deliver End-to-End Project. I build websites from scratch, translating custom designs into clean, organized, efficient, and scalable code.",
    text_readMore:
      "My focus is on optimizing websites for top performance, usability, and responsiveness across various operating systems and screen sizes, spanning from mobile and tablet to desktop. I stay up to date on the latest technology and coding trends, ensuring my work reflects current best practices and innovations. My services include meticulous debugging, problem-solving, and maintenance to ensure sustained functionality.",
    text_skills: "I translate design into code by using :",
    skills: "HTML, CSS, JavaScript & React JS.",
  },
];
