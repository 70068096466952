export const menuData = [
  {
    _id: 1,
    name: "Home",
    to: "hero",
    mobOffset: 0,
    desktopOffset: 0,
  },
  {
    _id: 2,
    name: "What\nI Do",
    to: "what-i-do",
    mobOffset: 3,
    desktopOffset: 50,
  },
  {
    _id: 3,
    name: "My\nServices",
    to: "my-services",
    mobOffset: 3,
    desktopOffset: 50,
  },
  {
    _id: 4,
    name: "My\nWork",
    to: "selected-work",
    mobOffset: 3,
    desktopOffset: 50,
  },
  {
    _id: 5,
    name: "Contact me",
    to: "let-s-connect",
    mobOffset: 3,
    desktopOffset: 50,
  },
];
